@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Roboto+Slab:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Round|Material+Icons+Outlined');
.material-icons-round.md-14 { font-size: 14px; }
.material-icons-round.md-16 { font-size: 16px; }
.material-icons-round.md-18 { font-size: 18px; }
.material-icons-round.md-24 { font-size: 24px; vertical-align: middle;}
.material-icons-round.md-28 { font-size: 28px; }
.material-icons-round.md-32 { font-size: 32px; }
.material-icons-round.md-36 { font-size: 36px; }
.material-icons-round.md-48 { font-size: 48px; }
.material-icons-outlined.md-14 { font-size: 14px; }
.material-icons-outlined.md-16 { font-size: 16px; }
.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-20 { font-size: 20px; }
.material-icons-outlined.md-22 { font-size: 22px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-28 { font-size: 28px; }
.material-icons-outlined.md-32 { font-size: 32px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }

h1{
  color: black;
}

.linkh1 {
  text-decoration: none;
}

.dataBlock{
  max-width: 600px;
  text-align: left;
  margin: 0 auto;
}

.dataBlock h2{
  text-align: left;
}

.dataBlock label{
  text-align: left;
}

.dataBlockLight{
  font-weight: 300;
  font-size: 18px;
}

.dataBlockTip{
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
  margin-top: -15px;
}

.floatRight{
  float: right;
}


.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sectionLine{
  height: 1px;
  width: 100%;
  background-color: #dfe6e9;
  margin: 55px auto;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.meter { 
	height: 10px;  /* Can be anything */
	position: relative;
	background: transparent;
	-moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	border-radius: 25px;
	padding: 0px;
  width: 90%;
  margin: 0 auto;
}
.meter > span {
  display: block;
  height: 100%;
  -moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	border-radius: 25px;
  background-color: #4698CA;
  position: relative;
  overflow: hidden;
  transition: width 1.25s;
}

a{
  color: black;
}



.pageButtons{
  cursor: pointer;
}

.pageNumber{
  color: #2E86DE;
  font-size: 20px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 600;
  vertical-align: middle;
}

.App-link {
  color: #61dafb;
}

.logo{
  width: 75%;
  max-width: 100px;
}
.logoContainer{
  float: left;
  width: 75%;
  max-width: 300px;
}

.navMenu{
  position: fixed; /* Stay in place */
  text-align: center;
  z-index: 1000000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 168, 255);
  background-color: rgba(0, 168, 255, 0.95);
}
.navBtnBox{
  position: absolute;
  top: 90px;
  left: 15px;
  right: 15px;
  text-align: left;
}

.navLightLine{
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: whitesmoke;
  padding: 0px;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  opacity: 0.6;
}

.menuButton{
  float: left;
  margin: 25px 10px;
  vertical-align: bottom;
  transition: all 0.3s ease;
}

.signInButton{
  position: absolute;
  top: 30px;
  right: 25px;
  opacity: 0.60;
  border: 0px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
}
.signInButton:hover{
  opacity: 1.00;
  transition: all 0.3s ease;
}

.signInOffset{
  right: 75px;
}

.signInButtonLeft{
  right: auto;
  left: 25px;
}

.centerList{
  text-align: left;
  margin: 0 auto 15px;
  display: inline-block;
}

.centerList ul{
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  line-height: 32px;
  font-size: 22px;
  font-weight: 500;
}

.centerList li{
  padding-left: 40px;
  background-image: url(Check.svg);
  background-repeat: no-repeat;
  background-position: 0 0.1em;
  background-size: 28px;
}

.centerList h3{
  text-align: center;
  margin-bottom: 5px;
  font-size: 24px;
}

p {
  font-size: 18px;
}

.fullScreenButton{
  position: absolute;
  top: 30px;
  right: 25px;
  opacity: 0.40;
  border: 0px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10000000;
}
.fullScreenButton:hover{
  opacity: 1.00;
  transition: all 0.3s ease;
}

.mobileMenu{
  visibility: hidden;
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 25px 10px;
  vertical-align: bottom;
  transition: all 0.3s ease;
}

@media screen and (max-width: 996px) {
  .menuContainer{
    clear: both;
  }
  .mobileMenu{
    visibility:visible;
    display: block;
  }
  /* .signInButton{
    float: left;
    margin: 27px 5%;
    vertical-align: bottom;
    visibility: hidden;
    display: none;
    width: 90%;
    text-align: left;
    transition: all 0.3s ease;
  }
  .signInButton.responsive{
    visibility: visible;
    display: block;
  } */
  .menuButton{
    float: left;
    margin: 25px 5%;
    vertical-align: bottom;
    visibility: hidden;
    width: 90%;
    text-align: left;
    display: none;
    transition: all 0.3s ease;
  }
  .menuButton.responsive{
    visibility: visible;
    display: block;
  }
}

.zeroPadMar{
  margin: 0px;
  padding: 0px;
}

.Appheader{
  padding-top:15px;
  padding-bottom:35px;
  margin-left: 0px;
  margin-right: 0px;
  text-align: center;
  transition: all 0.3s ease;
  background-color: #E6F2FC;
  /* rgba(9, 132, 227,0.1); */
  /* whitesmoke */
  /* clip-path: polygon(100% 0, 100% 60%, 50% 100%, 0 60%, 0 0); */
  clip-path: polygon(0 0, 100% 0, 100% 74%, 0% 100%);
}

@media screen and (max-width: 750px) {
  header{
    /* clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0); */
    clip-path: polygon(0 0, 100% 0, 100% 84%, 0% 100%);

  }
}

.ActionBar{
  height: 60px;
  background-color: whitesmoke;
}

/*test*/
.Grid {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  align-items: center;
  grid-gap: 30px;
  
}

.GridItem {
  width:100%;
  max-width: 300px;
  height: 375px;
  cursor: pointer;
  background-color: white;
  margin: auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 2px 10px -5px;
  transition: 0.3s;
}
.plainItem {
  height: 95px;
}

.answerOptionItem {
  height: 75px;
  display: table;
  color: black;
}

.answerOptionItem h2 {
  font-size: calc(16px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  width: 92%;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  margin: 20 auto;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.gridSelected{
  background-color: black;
  color: white;
}

.GridItem:hover {
  box-shadow: 0px 5px 15px -5px;
}
.GridImageContainer {
  height: 66%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.GridImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  position: center center;
  vertical-align: middle;
}
.grayscaleImage {
  filter: grayscale(100%);
}
h2.GridItemTitle {
  color: black;
  font-size: calc(16px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  width: 92%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
}
h2.GridItemPrice {
  color: white;
  font-size: calc(22px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 700;
  min-width: 40%;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 10px -20px;
  padding: 8px 12px;
  background-color: #2E86DE;
  border-radius: 15px 0px 0px 15px;
  text-align: left;
  position: absolute;
  top: 8px;
  right: 16px;
}
p.GridItemDescription {
  color: black;
  font-size: calc(12px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;

  margin: 10px 10px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
}

footer{
  text-align: left;
  background-color: whitesmoke;
  padding: 15px;
  line-height: 1.5em;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-top: 50px;
}

.routes{
  min-height: 40vh;
}


p.thin{
  text-align: center;
  background-color: transparent;
  line-height: 1.5em;
  color: black;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.footerBrand {
  width:130px;
  height: 130px;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
}

h2.footer {
  color: black;
  font-size: 16px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 6px;
}

p.footer {
  color: black;
  font-size: 16px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 400;
}
.footerInfo {
  display: inline-block;
  position: relative;
  margin-left: 25px;
  vertical-align: top;
  text-align: left;
}

@media screen and (max-width: 750px) {
  footer{
    text-align: center;
    background-color: whitesmoke;
    padding: 15px;
    line-height: 1.5em;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .footerInfo {
    text-align: left;
    margin-left: 5px;
  }
}

.lightLine{
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: gray;
  padding: 0px;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.powered {
  color: grey;
  font-size: 13px;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.row-content-text{
  text-align: left;
}
li { 
  margin-bottom: 8px;  
}

.textCenter{
  text-align: center;
}

.comingSoon{
  height: 60vh;
  text-align: center;
}

.detailView{
  padding-top: 5vw;
  margin: 0 auto;
  margin-top: -120px;
  text-align: center;
}

.DetailItemTitle {
  color: black;
  font-size: calc(32px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  word-wrap: normal;
  margin: 10px 10px;
  text-align: left;
  vertical-align: middle;
}
.CityTitle {
  color: #8395a7;
  font-size: calc(22px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
  vertical-align: middle;
  line-height: 58px;
  float: right;
}
.DetailItemPrice {
  color: white;
  font-size: calc(32px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 700;
  width: 25%;
  min-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
  float:right;
  margin: 10px 0px;
  padding: 10px 15px;
  background-color: #2E86DE;
  border-radius: 20px 0px 0px 20px;
  vertical-align: middle;
}
.DetailItemDescription {
  color: black;
  font-size: calc(18px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  width: 92%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
}
.DetailInfoBox{
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 80px;
  width: 90%;
  max-width: 1000px;
}



.galleryContainer{
  width:90%;
  max-width: 900px;
  margin: 0 auto;
  clear:both;
}

img{
  image-orientation: from-image;
}

.titleDiv{
  vertical-align: middle;
  margin-bottom: 90px;
}

.clearDiv{
  display:block;
  clear:both;
}

@media screen and (max-width: 750px) {
  .galleryContainer{
    width:100%;
    margin: 0 auto;
  }
}

.FeatureGrid {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  align-items: center;
  grid-gap: 20px;
  background-color: whitesmoke;
  border-radius: 15px;
  border: 1px solid #2E86DE;
  overflow:hidden;
  box-shadow: 0px 2px 10px -5px;
}

@media screen and (max-width: 760px) {
  .FeatureGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .FeatureGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 400px) {
  .FeatureGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.FeatureGridItem {
  width:100%;
  max-width: 100%;
  padding: 0px;
  background-color: transparent;
  margin: 0px;
  overflow:hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.featureSpan2{
  grid-column: span 1;
}
.featureSpan3{
  grid-column: span 1;
}

.FeatureItem{
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.featureIcon{
  color: black;
  vertical-align: middle;
}

.bottomAlignIcon{
  color: black;
  vertical-align: bottom;
}

.featureText{
  vertical-align: middle;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-size: 32;
  font-weight: 700;
  text-overflow: ellipsis;
  text-align: left;
}

.HighlightFeatureList{
  float: left;
}

.StripeElement {
  box-sizing: border-box;
  max-width: 600px;
  height: 40px;
  margin : 0 auto;
  margin-bottom: 20px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #989a9c;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripeCustField {
  width: 100%;
  max-width: 600px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-appearance: none;
}
.numInputField {
  width: 80px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-appearance: none;
}

.numInputContainer{
  display: inline;
  margin-left: 25px;
  margin-right: 25px;
}

.label {
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 600px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}
.labelRed {
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 600px;
  color: red;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}

.redtext{
  color: red;
}

.fullscreen{
  position: fixed;
  z-index: 100;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: black;
}

.slideImg{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s linear;
}

.DescTextarea {
  height: 250px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}

.checkbox{
  margin: 10px;
	display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease;
}

/* #2E86DE; */
.mainButton {
  text-transform: uppercase;
  background-color: black;
  border: 3px solid transparent;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
}

.mainButton:disabled {
  background-color:whitesmoke;
  border: 2px solid lightgray;
  color: lightgray;
}
.mainButton:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid lightgray;
  color: lightgray;
}

.mainButton:hover{
  background-color:transparent;
  border: 3px solid white;
  color:white;
  transition: all 0.3s ease;
}

.mainButtonContrast {
  text-transform: uppercase;
  background-color: black;
  border: 3px solid transparent;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
  z-index: 10;
}

.mainButtonContrast:hover{
  background-color:transparent;
  border: 3px solid black;
  color:black;
  transition: all 0.3s ease;
}

.mainButtonContrast:disabled {
  background-color:whitesmoke;
  border: 2px solid lightgray;
  color: lightgray;
}
.mainButtonContrast:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid lightgray;
  color: lightgray;
}

input[type='file']{
  width: 100%;
}

input::file-selector-button {
  text-transform: uppercase;
  background-color: black;
  border: 3px solid transparent;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
  z-index: 10;
}

input::file-selector-button:hover {
  background-color:transparent;
  border: 3px solid black;
  color:black;
  transition: all 0.3s ease;
}


.secondaryButton {
  text-transform: uppercase;
  background-color: rgba(0, 0, 0,0.4);
  border: 3px solid white;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
}

.secondaryButton:disabled {
  background-color:whitesmoke;
  border: 2px solid lightgray;
  color: lightgray;
}
.secondaryButton:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid lightgray;
  color: lightgray;
}

.secondaryButton:hover{
  background-color:black;
  border: 3px solid black;
  color: white;
  transition: all 0.3s ease;
}

.lineItem {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  padding: 0px 22px 0px 0px;
  margin: 0px 10px 10px;
  width: 100% - 20px;
  max-width: 600px;
  height: 100px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
}

.lineItemLong {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  padding: 20px 22px 20px 22px;
  width: 90%;
  max-width: 1000px;
  
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
}

.AddLineItem{
  color: black;
  font-weight: bold;
  text-align: center;
  background-color: white;
  border: 2px solid black;
}

p.lineItemTextColumn {
  float: left;
  margin: 0px 20px;
  font-weight: 500;
  white-space: pre-wrap;
}
.lineItemColumnHead {
  font-weight: 300;
}
.lineImage{
  height: 100%;
  width: 125px;
  overflow: hidden;
  object-fit: cover;
  vertical-align: middle;
  margin: 0px;
  margin-right: 20px;
}
.lineItem:hover .lineItemLong:hover .AddLineItem:hover {
  box-shadow: 0px 2px 8px -5px black;
  transition: all 0.3s ease;
}
.AddLineItem:hover {
  box-shadow: 0px 2px 10px -5px black;
  transition: all 0.3s ease;
}
.lineItemSelected {
  border: 2px solid black;
  background-color: white;
  box-shadow: 0px 2px 8px -5px black;
}

p.lineItemText {
  float: left;
}
.lineItemSub {
  font-size: calc(15px);
  float: right;
  color: rgb(52, 142, 216);
  vertical-align: middle;
}

.lineButton{
  opacity: 0.60;
  border: 0px;
  background-color: transparent;
  color: #2E86DE;
  float: right;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.lineButton:hover{
  opacity: 1;
  transition: all 0.3s ease;
}

.spacer{
  width: 100%;
  height: 25px;
  margin: 0;
  padding: 0;
}

.menuItemDetail-back {
  text-align: center;
  position: fixed; /* Stay in place */
  z-index: 10000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.25); /* Black w/ opacity */
}

.loaderOverlay {
  width: 140px;
  background-color: white;
  padding: 20px;
  margin: 20% auto; /* 15% from the top and centered */
  border-radius: 10px;
}

.textarea{
  height: 150px;
  font-family: Roboto, Helvetica Neue, sans-serif;
  box-sizing: border-box;
  max-width: 600px;
  margin : 0 auto;
  margin-bottom: 20px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 100%;
  max-width: 600px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-appearance: none;
}

.textarea--focus {
  box-shadow: 0 1px 3px 0 #989a9c;
}

.authCard{
  text-align: center;
  padding: 50px;
}

.authCard h2{
  text-transform: uppercase;
  color:#000826;
  font-size: 18px;
}

.Badge {
  background-color: #1dd1a1;
  border-radius: 8px;
  color: white;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 10px;
  font-size: 18px;
  font-family: Roboto, Helvetica Neue, sans-serif;
  transition: all 0.3s ease;
  vertical-align: middle;
}

.SoldBadge {
  background-color: #ff6b6b !important;
}

.optionContainer{
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
}

.optionLine {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px 32px;
  margin: 0px 10px 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
  float: left;
}
.optionLine:hover {
  box-shadow: 0px 2px 8px -5px black;
  transition: all 0.3s ease;
}
.optionLineSelected {
  border: 2px solid black;
  background-color: white;
  box-shadow: 0px 2px 8px -5px black;
}

p.optionText {
  float: left;
}
p.optionPrice {
  font-size: calc(15px);
  float: right;
  color: #2E86DE;
}


.filterBar{
  text-align: left;
  padding: 10px 40px 0px;
}

.bubbleField {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: text;
  width: 100px;
  height: 28px;
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px 32px;
  margin: 0px 10px 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
  float: left;
}

.bubbleButton {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px 32px;
  margin: 0px 10px 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
  float: left;
}
.filterClearButton {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px 10px 10px 0px;
  margin: 0px 0px 0px 0px;
  transition: all 0.3s ease;
  float: left;
  opacity: 0.5;;
}
.filterClearButton:hover {
  transition: all 0.3s ease;
  opacity: 1.0;;
}
.bubbleButton:hover {
  box-shadow: 0px 2px 8px -5px black;
  transition: all 0.3s ease;
}
.bubbleSelected {
  border: 2px solid black;
  background-color: white;
  box-shadow: 0px 2px 8px -5px black;
}



.linkButton{
  background-color: transparent;
  border: 0;
  /* text-decoration: underline; */
  color: black;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 800;
}
.linkButton:hover{
  transition: all 0.3s ease;
  opacity: 0.6;
}
.linkButton:disabled {
  color: #c8d6e5;
}
.linkButton:disabled:hover {
  color: #c8d6e5;
}

.Loader{
  min-height: 25px;
  padding: 75px;
}

.bodyDiv{
  width: 90%;
  text-align: center;
  margin: 0 auto;
  padding: 0px;
}

body{
  background-color: white
}

.bodyTxt{
  max-width: 800px;
  margin: 0 auto;
}

.nextQuestion{
  opacity: 0.50;
}

.gameCodeInput{
  max-width: 150px;
}

.optionInput{
  max-width: 60%;
}

.fullWidth{
  width: 100%;
}

.EditableH{
  display: inline;
  text-align: left;
}
.textLeft{
  text-align: left;
}

.barButton{
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: black;
  transition: all 0.3s ease;
  cursor: pointer;
  vertical-align: middle;
  width: 44px;
  opacity: 1.0;
}
.barButton:hover{
  transition: all 0.3s ease;
  opacity: 0.6;
}

.favButton{
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
}
.favButton:hover{
  transition: all 0.3s ease;
  opacity: 0.6;
}

.favButtonGridContainer{
  position: absolute;
  top: 20px;
  left: 10px;
}

.closeButton {
  background-color: transparent;
  color: black;
  border: 0px;
  opacity: 0.6;
  cursor: pointer;
  float: right;
  transition: all 0.3s ease;
}
.closeButton:hover{
  opacity: 1.0;
  transition: all 0.3s ease;
}


.modal-back {
  position: fixed; /* Stay in place */
  text-align: center;
  z-index: 1000000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.25); /* Black w/ opacity */
}
.modal-content {
  background-color: white;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  box-shadow: 0px 2px 10px -5px;
  border-radius: 15px;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 700px;
  display: inline-block;
}

.modal-frame {
  background-color: white;
  margin: 0 auto; /* 15% from the top and centered */
  box-shadow: 0px 2px 10px -5px;
  border-radius: 15px;
  width: 90%; /* Could be more or less, depending on screen size */
  max-width: 700px;
  padding-bottom: 15px;
  display: inline-block;
}

.textIconImg{
  height: 30px;
  width: 30px;
  margin-right: 10px;
  object-fit: cover;
  overflow: hidden;
  position: center center;
  vertical-align: middle;
  border-radius: 15%;
}

.taglineText{
  font-weight: normal;
  font-size: 22px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}


.Navbar {
  background-color: rgba(255.0, 255.0, 255.0, 0.0);
  position: absolute; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  left: 0;
  width: 100%; /* Full width */
  height: 150px;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  z-index: 1000000;
  transition: all 0.3s ease;

  overflow: visible;
}
.NavSpacer{
  height: 150px;
  width: 100%;
  background-color: white;
}

.NavContents{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  height: 100%;
  overflow: visible;
}

.NavbarBrand {
  height: 120px;
  float: left;
  transition: all 0.3s ease;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: transparent;
  display: block;
  overflow: visible;
  padding-top: 40px;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: right;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: transparent;
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Add an active class to highlight the current page */

.topnav a.active {
  background-color: transparent;
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.topnav a.dark {
  background-color: black;
  color: white;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
  transition: all 0.3s ease;
} 

.dropdown {
  float: right;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.dropdown:hover .dropbtn{
  background-color: #f9f9f9;
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background to dropdown links on hover */
.dropdown-content a:hover {
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}

.featureBlock{
  width: 80%;
  margin: 0 auto;
  text-align:center;
  display: inline-block;
}

.featureBlockImg{
  object-fit: fill;
  width: 100%;
  height: 100%;
}
.featureBlockImgContainer{
  /* float: left; */
  max-width: 250px;
  width: 45%;
  overflow: hidden;
  position: relative;
	left: 0;
  display: inline-block;
  vertical-align:middle;
}

.featureBlockChildren{
  /* float: left;  */
  width: 45%;
  height: 100%;
  position: relative;
	left: 0;
  display: inline-block;
  vertical-align:middle;
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
  /* padding-top: 10px; */
}


@media screen and (max-width: 600px) {
  .featureBlockImgContainer{
    width: 100%;
  }
  .featureBlockChildren{
    width: 90%;
  }
}

.priceDiv{
  background-color: transparent;
  margin: 0 auto;
  max-width: 900px;
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
}

.FlexGrid {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: center;
  grid-gap: 30px;
  flex-wrap: wrap;  
}

.GridItem {
  width:100%;
  max-width: 300px;
  height: 375px;
  cursor: pointer;
  background-color: white;
  margin: auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 2px 10px -5px;
  transition: 0.3s;
  padding: 0 15px;
}

.priceItem {
  height: 350px;
  max-width: 250px;
}
.priceItem h1 {
  font-size: 22px;
}
.priceItem p {
  font-size: 16px;
}
.priceText {
  font-size: 42px;
  font-weight: bolder;
}

.priceItem button{
  background-color: black;
  color: white;
  border: 3px solid black;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px -5px;
}

.topBorderBlue{
  border-top: 6px solid #0984E3;
}
.topBorderGrey{
  border-top: 6px solid lightgray;
}

.priceItem button:hover{
  background-color:whitesmoke;
  border: 3px solid black;
  color:black;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px -5px;
}

.priceButtonHightlight{
  background-color: #0984E3 !important;
  border: 3px solid #0984E3 !important;
  color: white !important;
}

.priceButtonHightlight:hover{
  background-color:whitesmoke !important;
  border: 3px solid #0984E3 !important;
  color:#0984E3 !important;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px -5px;
}

.GridItem:hover {
  box-shadow: 0px 5px 15px -5px;
}


 /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 750px) {
  .topnav a , .dropdown .dropbtn {display: none;}
  .topnav a.icon {
    float: right;
    padding: 5px 7px;
    margin-bottom: 10px;
    display: block;
    transition: none;
  }
  .NavbarBrand {
    height: 75px;
    float: left;
    transition: all 0.3s ease;
  }
  .topnav {
      background-color: transparent;
      display: block;
      overflow: hidden;
      padding-top: 30px;
    }
}


/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 750px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    transition: all 0.3s ease;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: right;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
  .topnav.responsive .dropdown {float: none;}
.topnav.responsive .dropdown-content {position: relative;}
.topnav.responsive .dropdown .dropbtn {
  display: block;
  width: 100%;
  text-align: right;
}
  .Navbar.responsive {
      overflow: hidden;
      background-color: rgba(255.0, 255.0, 255.0, 0.95);
      position: absolute; /* Set the navbar to fixed position */
      top: 0; /* Position the navbar at the top of the page */
      left: 0;
      width: 100%; /* Full width */
      height: 200%;
      padding: 10px;
      text-align: center;
      box-sizing: border-box;
      z-index: 1000000;
      transition: all 0.3s ease;
    }
  .NavbarBrand.responsive {
      display: none;
      transition: all 0.3s ease;
  }
} 


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
